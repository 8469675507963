.sticky-top {
    /* Add styles for sticky positioning if necessary */
}

.float {
    right: 0;
    position: fixed;
    width: 50px;
    margin-top: 150px;
    transition: all 0.3s linear;
}
.float li {
    height: 45px;
    width: 50px; /* Set a fixed width for the list item */
    position: relative;
    transition: width 0.3s ease; /* Add transition effect for width */
}

.float li a {
    position: relative;
    border-radius: 7px 0px 0px 7px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    line-height: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    transition: all 0.3s linear;
}

.float li a i {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    font-size: 17px;
}

.float li a span {
    display: none;
    position: absolute;
    top: 50%;
    left: 60px;
    transform: translateY(-50%);
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.float li:hover {
    width: 200px; /* Change width on hover */
}

.float li:hover a span {
    display: block;
}

.float li:nth-child(1) a {
    background: #333333;
}

.float li:nth-child(2) a {
    background: #5a82fc;
}

.float li:nth-child(3) a {
    background: #333333;
}

.float li:nth-child(4) a {
    background: #5a82fc;
}

.float li:nth-child(5) a {
    background: #333333;
}
