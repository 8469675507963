@keyframes slideInFromRight {
    from {
        left: 100%;
    }
    to {
        left: 0;
    }
}

@keyframes slideInFromTop {
    from {
        top: -100%;
    }
    to {
        top: 33.33%;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
