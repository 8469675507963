/* styles.css */

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


. {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.p {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}
.h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}
.h5 {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}







.p {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}


.p{
    font-family: "Nunito Sans", sans-serif;
    font-weight: 500;
    font-style: normal;
}
